<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="30"
  >
    <div class="container mt-4">
      <div>
        <button
          class="
            btn btn-white
            text-orange
            btn-pill
            mx-auto
            btn-drawer-open
            text-uppercase
            btn-float
          "
        >
          <i class="icon-filter"></i>
          Filtros
        </button>
        <div class="bottom-drawer">
          <div class="bottom-drawer-header">
            <h3 class="font-weight-bold mb-0 my-auto">Filtros</h3>
            <button class="btn btn-lg btn-link btn-drawer-close ml-auto p-0">
              <i class="icon-close"></i>
            </button>
          </div>
          <sellers-sales-form ref="salesForm" :onUpdate="this.updatedFilters" />
        </div>
        <div class="row justify-content-center font-weight-light">
          <div>
            <span v-if="historic.days.length">{{ historic.count }}</span>
            <span v-if="historic.days.length > 0">{{
              ' transações encontradas'
            }}</span>
          </div>
          <div class="ml-3 d-none d-lg-inline-block">
            <WhiteLabel>
              <a
                href="#"
                class="text-uppercase text-sm p-0"
                @click="cleanupFilters"
                >limpar filtros</a
              >
            </WhiteLabel>
          </div>
        </div>
        <SellersSalesTotalizers :totals="historic.totals" />
      </div>
    </div>
    <div class="container" v-if="historic !== null">
      <SellersSalesList :historic="historic" />
    </div>
    <div v-if="historic.days.length === 0">
      <div class="text-center">
        <h4>Nenhuma transação encontrada.</h4>
      </div>
    </div>
  </div>
</template>

<script>
import SellersSalesForm from '@/components/financial/sales/sales-filters/SellersSalesForm';
import SellersSalesTotalizers from '@/components/financial/sales/sales-filters/SellersSalesTotalizers';
import SellersSalesList from '@/components/financial/sales/SellersSalesList';
import SellerSalesHistoricFilters from '@/models/SellerSalesHistoricFilters';
import Totalizers from '@/models/Totalizers';
import PaymentsApi from '@/services/v3/PaymentsApi';
import { cpfCnpj } from '@/validations/cpfCnpj';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'SellersSalesHistory',
  data() {
    return {
      historic: {
        days: [],
      },
      historicFull: null,
      totalizers: null,
      tempTotalizers: null,
      perPage: 30,
      isInterval: true,
      isValidInterval: true,
      filters: {},
      filtersOn: false,
    };
  },
  components: {
    SellersSalesForm,
    SellersSalesList,
    SellersSalesTotalizers,
    WhiteLabel,
  },
  async mounted() {
    $('.btn-drawer-close').on('click', () => this.hide());
    $('.btn-drawer-open').on('click', () => this.show());
  },
  methods: {
    hide() {
      $('.bottom-drawer').removeClass('show');
    },
    show() {
      $('.bottom-drawer').addClass('show');
      this.filtersOn = true;
    },
    cleanupFilters() {
      this.$refs.salesForm.filters = new SellerSalesHistoricFilters();
      this.$refs.salesForm.selectedDate.start = new Date().addDays(-7);
      this.$refs.salesForm.selectedDate.end = new Date();
      this.$refs.salesTotalizers.totalizers = new Totalizers();
    },
    async updatedFilters(filters) {
      const api = new PaymentsApi();
      const historic = await api.getSellersTransactions(filters);
      this.historic = historic;
      this.$bus.$emit('spinner-stop');
    },
    loadMore: async function () {},
  },
};
</script>
