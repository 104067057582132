import { withParams } from 'vuelidate/lib';

const onlyNumbers = withParams({ type: 'onlyNumbers' }, value => {
  if (!value) return true;

  var regex = new RegExp('^\\d*$');
  return regex.test(value);
});

export { onlyNumbers };
