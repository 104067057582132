<template>
  <div class="mb-5">
    <div class="row" v-for="(day, key) in historic.days" :key="key">
      <div class="col-12 h4 mt-4 mb-3">
        <span class="font-weight-light">{{ day.day | moment('ddd') }},</span>
        <span class="font-weight-bold">{{
          day.day | moment('DD/MM/YYYY')
        }}</span>
      </div>
      <div class="col-12 col-w-full">
        <div v-for="holder in day.holders" :key="holder.id">
          <span class="font-weight-bold">{{ holder.fullName }}</span>
          <span class="font-weight-light"
            >{{ ' ' }}({{ holder.taxDocument }})</span
          >
          <div
            class="card card-action py-4 mt-3 mb-3"
            v-for="transaction in holder.transactions"
            :key="transaction.id"
            :class="[
              transaction.status === 'paid'
                ? 'card-status-success'
                : transaction.status === 'canceled'
                ? 'card-status-canceled'
                : transaction.status === 'pending'
                ? 'card-status-processing'
                : transaction.status === 'expired'
                ? 'card-status-canceled'
                : transaction.status === 'unauthorized'
                ? 'card-status-notauthorized'
                : transaction.status === 'disputed'
                ? 'card-status-canceled'
                : transaction.status === 'overdue'
                ? 'card-status-delayed'
                : '',
            ]"
          >
            <router-link
              class="row m-0"
              :to="{
                name: 'payment-details',
                params: { paymentId: transaction.id, isPartner: true },
              }"
            >
              <div class="col col-xl-3 order-1 d-flex">
                <div class="my-lg-auto text-truncate">
                  <div class="d-flex">
                    <span
                      :class="[
                        transaction.cardBrand !== 'visa' ? '' : 'visa',
                        transaction.cardBrand !== 'mastercard'
                          ? ''
                          : 'mastercard',
                        transaction.cardBrand !== 'amex' ? '' : 'amex',
                        transaction.cardBrand !== 'dinersclub'
                          ? ''
                          : 'dinersclub',
                        transaction.cardBrand !== 'elo' ? '' : 'elo',
                        transaction.cardBrand !== 'hipercard'
                          ? ''
                          : 'hipercard',
                        transaction.cardBrand !== 'banesecard'
                          ? ''
                          : 'banesecard',
                        transaction.method === 'bankSlip'
                          ? 'boleto'
                          : transaction.method === 'bankSlipInstallment'
                          ? 'carne'
                          : '',
                      ]"
                      class="
                        payment-method payment-method-lg
                        my-auto
                        ml-0 ml-lg-3
                        mr-2
                      "
                    ></span>
                    <div class="text-secondary text-sm my-auto">
                      <span class="align-middle">{{
                        transaction.cardFinal
                      }}</span>
                      <span class="ml-1 align-middle">{{
                        transaction.method === 'credit'
                          ? 'crédito'
                          : transaction.method === 'bankSlip'
                          ? 'boleto'
                          : transaction.method === 'bankSlipInstallment'
                          ? `parcela ${transaction.installment}/${transaction.installments}`
                          : 'débito'
                      }}</span>
                      <span
                        class="badge badge-outline-secondary badge-pill ml-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Essa transação será repassada e administrada pela adquirente e não é contabilizada junto às demais transações."
                        v-if="transaction.isVan"
                        >VAN</span
                      >
                      <span
                        class="
                          badge badge-light-gray
                          text-secondary
                          badge-pill
                          ml-2
                        "
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="
                          transaction.status === 'pending'
                            ? 'Essa transação foi pré autorizada e pode ter o valor alterado em sua finalização.'
                            : 'Essa transação foi pré autorizada'
                        "
                        v-if="transaction.dueCaptureDate !== null"
                        >PRÉ</span
                      >
                    </div>
                  </div>
                  <div
                    class="
                      text-truncate text-sm
                      ml-0 ml-lg-3
                      mb-1
                      text-secondary
                    "
                  >
                    <span>{{ transaction.note }}</span>
                  </div>
                </div>
              </div>
              <div
                class="col col-lg-auto order-4 order-lg-2 d-flex text-secondary"
              >
                <div class="my-auto">
                  <div
                    class="d-flex mb-1"
                    v-if="transaction.payerName !== null"
                  >
                    <span class="d-flex">
                      <i class="icon-user m-auto"></i>
                    </span>
                    <div class="ml-2 small-paragraph">
                      <span class="text-sm">{{ transaction.payerName }}</span>
                      <br />
                      <span class="text-xs">{{
                        transaction.payerTaxDocument
                      }}</span>
                    </div>
                  </div>
                  <div class="text-sm">
                    <i
                      v-if="
                        transaction.isPinPad !== null && transaction.isPinPad
                      "
                      class="icon-screen-smartphone"
                    ></i>
                    <i
                      v-if="
                        transaction.isPinPad !== null && !transaction.isPinPad
                      "
                      class="icon-screen-desktop"
                    ></i>
                    <span>{{
                      transaction.createdAt | moment('HH:mm:ss')
                    }}</span>
                    <span>• #{{ transaction.id }}</span>
                    <span v-if="transaction.nsu !== null"
                      >• NSU {{ transaction.nsu }}</span
                    >
                    <span v-if="transaction.number !== null"
                      >• Nº {{ transaction.number }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="
                  col-12 col-lg
                  order-3
                  d-flex
                  text-center text-sm
                  mb-3 mb-lg-0
                "
              >
                <span
                  v-if="transaction.status === 'disputed'"
                  class="ml-auto my-auto text-danger"
                  >Contestada em
                  {{ transaction.disputedAt | moment('DD/MM/YYYY') }}</span
                >
                <span
                  v-if="transaction.inDispute === true"
                  class="ml-auto my-auto text-danger"
                  >Disputa em
                  {{ transaction.disputedOn | moment('DD/MM/YYYY') }}</span
                >
                <span
                  v-if="transaction.status === 'paid'"
                  class="ml-auto my-auto text-success"
                  >Pago em
                  {{
                    transaction.capturedAt !== null
                      ? transaction.capturedAt
                      : transaction.referenceDate | moment('DD/MM/YYYY')
                  }}</span
                >
                <span
                  v-if="transaction.status === 'pending'"
                  class="ml-auto my-auto text-delayed"
                  >Expira em
                  {{
                    transaction.dueCaptureDate !== null
                      ? transaction.dueCaptureDate
                      : transaction.nextDueDate | moment('DD/MM/YYYY')
                  }}</span
                >
                <span
                  v-if="transaction.status === 'canceled'"
                  class="ml-auto my-auto text-canceled"
                  >{{
                    transaction.canceledAt !== null ? 'Cancelada' : 'Expirou'
                  }}
                  em
                  {{
                    transaction.canceledAt !== null
                      ? transaction.canceledAt
                      : transaction.dueCaptureDate | moment('DD/MM/YYYY')
                  }}</span
                >
              </div>
              <div
                class="
                  col col-lg-3
                  order-2 order-lg-4
                  text-right text-secondary
                  mt-1
                  text-nowrap
                "
              >
                <span class="h3">{{
                  transaction.method === 'bankSlipInstallment'
                    ? transaction.amount
                    : transaction.status === 'paid'
                    ? transaction.amountPaid
                    : transaction.amount
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                }}</span>
                <br />
                <span
                  class="text-xs"
                  v-if="
                    transaction.installments > 1 &&
                    transaction.method !== 'bankSlipInstallment'
                  "
                  >{{ transaction.installments }} X
                  {{
                    transaction.installmentValueAuthorized
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}</span
                >
                <div v-if="transaction.commissionAmount" class="row">
                  <div class="col-12 text-right mt-3">Comissão:</div>
                  <div class="col-12 text-right">
                    <strong>{{
                      transaction.commissionAmount
                        | currency('R$ ', 2, {
                          decimalSeparator: ',',
                          thousandsSeparator: '.',
                        })
                    }}</strong>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentsApi from '@/services/PaymentsApi';
import { PermissionList } from '@/models/Permission';

export default {
  name: 'SellersSalesList',
  // eslint-disable-next-line vue/require-prop-types
  props: ['historic'],
  data() {
    return {
      customer: {
        email: '',
      },
    };
  },
};
</script>
