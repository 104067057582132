<template>
  <div class="row" v-if="totals">
    <div
      class="
        col-12 col-w-full
        flex-nowrap
        overflow-auto
        d-flex
        py-4
        px-4 px-xl-2
      "
    >
      <div class="totalizer-card" id="success-tlz">
        <label class="totalizer-checkbox">
          <input type="checkbox" :checked="checkboxes.paid" />
          <div class="card-checkbox">
            <div class="card">
              <span class="sales-quantitative">{{ totals.paidQuantity }}</span>
              <span class="totalizer-title mb-3">PAGAS / APROVADAS</span>
              <div class="status mb-3"></div>
              <span class="money-totalizer mb-1">{{
                totals.paidAmount
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}</span>
            </div>
          </div>
        </label>
      </div>
      <div class="totalizer-card" id="success-tlz">
        <label class="totalizer-checkbox">
          <input type="checkbox" :checked="checkboxes.paid" />
          <div class="card-checkbox">
            <div class="card">
              <span class="sales-quantitative">...</span>
              <span class="totalizer-title mb-3">COMISSÃO</span>
              <div class="status mb-3"></div>
              <span class="money-totalizer mb-1">{{
                totals.comissionAmount
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}</span>
            </div>
          </div>
        </label>
      </div>
      <div class="totalizer-card" id="processing-tlz">
        <label class="totalizer-checkbox">
          <input type="checkbox" :checked="checkboxes.pending" />
          <div class="card-checkbox">
            <div class="card">
              <span class="sales-quantitative">{{
                totals.pendingQuantity
              }}</span>
              <span class="totalizer-title mb-3">em aberto</span>
              <div class="status mb-3"></div>
              <span class="money-totalizer mb-1">{{
                totals.pendingAmount
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}</span>
            </div>
          </div>
        </label>
      </div>
      <div class="totalizer-card" id="delayed-tlz">
        <label class="totalizer-checkbox">
          <input type="checkbox" :checked="checkboxes.overdue" />
          <div class="card-checkbox">
            <div class="card">
              <span class="sales-quantitative">{{
                totals.overdueQuantity
              }}</span>
              <span class="totalizer-title mb-3">vencidas</span>
              <div class="status mb-3"></div>
              <span class="money-totalizer mb-1">{{
                totals.overdueAmount
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}</span>
            </div>
          </div>
        </label>
      </div>
      <div class="totalizer-card" id="canceled-tlz">
        <label class="totalizer-checkbox">
          <input type="checkbox" :checked="checkboxes.cancelled" />
          <div class="card-checkbox">
            <div class="card">
              <span class="sales-quantitative">{{
                totals.expiredQuantity + totals.canceledQuantity
              }}</span>
              <span class="totalizer-title mb-3">expiradas / canceladas</span>
              <div class="status mb-3"></div>
              <span class="money-totalizer mb-1">{{
                (totals.expiredAmount + totals.canceledAmount)
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}</span>
            </div>
            <div
              class="text-center mt-2 transactions-alert"
              :disabled="!totals.canceled"
            >
              <span class="badge badge-pill badge-danger">{{
                totals.disputedQuantity
              }}</span
              >&nbsp;
              <span class="text-sm text-ghost">contestadas</span>
            </div>
          </div>
        </label>
      </div>
      <div class="totalizer-card" id="notauthorized-tlz">
        <label class="totalizer-checkbox">
          <input type="checkbox" :checked="checkboxes.unauthorized" />
          <div class="card-checkbox">
            <div class="card">
              <span class="sales-quantitative">{{
                totals.unauthorizedQuantity
              }}</span>
              <span class="totalizer-title mb-3">Não autorizadas</span>
              <div class="status mb-3"></div>
              <span class="money-totalizer mb-1">{{
                totals.unauthorizedAmount
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}</span>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Totalizers from '@/models/Totalizers';
export default {
  name: 'SellersSalesTotalizers',
  data() {
    return {
      checkboxes: {
        paid: true,
        pending: true,
        overdue: true,
        cancelled: true,
        unauthorized: true,
      },
    };
  },
  props: {
    totals: {
      type: Object,
      default: () => {
        return {};
      },
    },
    handleChangedTotalizers: {
      type: Function,
      default: () => 1,
    },
  },
};
</script>
